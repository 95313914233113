<script lang="ts">
	import * as Glide from "@glidejs/glide";
	import { disableGlidePerSlideAmount } from "../../utils/disableGlidePerSlideAmount";
	import type { Action } from "svelte/action";
	import arrowRightLong from "../../../../core/assets/icons/arrow-right-long.svg?raw";
	import Link from "../Link.svelte";
	import type { Reference } from "../../../../core/schema/Reference";
	import { nbspify } from "../../../../core/utils/nbspify.js";
	import { textLengthLimit } from "../../../../core/utils/textLengthLimit";
	import { getReferenceDetailPath } from "../../../../core/schema/paths/getReferenceDetailPath";
	import DynamicPicture from "../Picture/DynamicPicture.svelte";
	import StaticPicture from "../Picture/StaticPicture.svelte";
	import { appName } from "../../../../core/schema/appName";
	import logo from "../../../../core/assets/logo.svg";
	import referencesTranslations from "../../templates/References.translations.json";
	import translations from "./ReferencesSlider.translations.json";
	import { getTranslate } from "../../utils/getTranslate";

	export let references: Reference[];

	const translate = getTranslate({
		...referencesTranslations,
		...translations,
	});

	const glide: Action = (element: HTMLElement) => {
		if (references.length <= 1) {
			return;
		}

		const glideOptions = {
			type: "carousel",
			startAt: 0,
			perView: 1,
			autoplay: 10000,
			hoverpause: true,
			animationDuration: 1250,
			animationTimingFunc: "ease-in-out",
			gap: 0,
			keyboard: true,
			bound: true,
		} satisfies Partial<Glide.Options>;

		// eslint-disable-next-line new-cap
		const glide = new Glide.default(element, glideOptions).mount();
		glide.on("resize", () => {
			disableGlidePerSlideAmount(glide, element);
		});

		// Manually handling bullet class toggle because Glider toggles the class after the transition is done. We toggle the class when the transition begins.
		const bullets = element.querySelectorAll(".glide__bullet");
		bullets[glide.index]?.classList.add("glide-bullet--active");

		glide.on("run", () => {
			for (const [index, bullet] of bullets.entries()) {
				bullet.classList.toggle("glide-bullet--active", index === glide.index);
			}
		});

		return {
			destroy(): void {
				glide.destroy();
			},
		};
	};
</script>

<div class="overflow-hidden rounded-lg bg-gray-300 lg:overflow-auto lg:p-8">
	<div use:glide class="glide relative flex items-center">
		<div class="glide__track relative grow" data-glide-el="track">
			<div class="glide__slides lg:h-[36.25rem]">
				{#each references as reference}
					<div class="glide__slide flex h-full flex-col-reverse gap-2 lg:grid lg:grid-cols-2">
						<div class="relative flex h-full w-full flex-col gap-3 rounded-lg p-3 lg:gap-6 lg:p-6 lg:py-0">
							<span class="text-xs uppercase tracking-[1.2px]">{translate("caseStudiesName")}</span>
							<h2 class="font-heading md:text-2md m-0 xl:text-xl">{textLengthLimit(nbspify(reference.title), 60)}</h2>
							<div class="flex h-full flex-col justify-between gap-2 lg:gap-4">
								<p class="text-2xs max-w-[28.125rem] leading-[160%] lg:text-sm">
									{textLengthLimit(nbspify(reference.perex), 300)}
								</p>
								<div class="mb-[2rem] flex flex-col lg:mb-[3.4375rem]">
									<Link
										asButton
										variant="primary"
										class="mt-4"
										icon={arrowRightLong}
										href="/{getReferenceDetailPath(reference)}"
									>
										{translate("continueReading")}
									</Link>
								</div>
							</div>
						</div>
						<div class="flex h-72 w-full overflow-hidden bg-black lg:h-full lg:rounded-lg">
							{#if reference.images[0]}
								<DynamicPicture
									class="h-full w-full overflow-hidden object-cover object-center"
									imgClass="w-full h-full object-contain object-center"
									alt={reference.title}
									image={reference.images[0]}
									width={660}
									height={660}
									bucket="storage"
									sourcesets={{
										"<xl": [444, 0],
										">xl": [660, 0],
									}}
								/>
							{:else}
								<StaticPicture
									alt="Logo {appName}"
									image={logo}
									class="h-full w-full overflow-hidden object-cover object-center"
									loading="eager"
									width={660}
									height={660}
								/>
							{/if}
						</div>
					</div>
				{/each}
			</div>
			<div
				class="glide__bullets absolute bottom-4 left-3 flex gap-2 lg:bottom-0 lg:left-6"
				data-glide-el="controls[nav]"
			>
				{#each references as _, index}
					<button
						aria-label={translate("goToImage", { position: index + 1 }, true)}
						class="glide__bullet h-1 w-8 rounded-sm bg-gray-400"
						data-glide-dir="={index}"
					></button>
				{/each}
			</div>
		</div>
	</div>
</div>

<style>
	@import "../../../node_modules/@glidejs/glide/dist/css/glide.core.min.css";
</style>
