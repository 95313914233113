import { type Category, categorySlugMap } from "../Category.js";
import type { Product } from "../Product.js";
import { solutionsPathMap } from "./solutionsPathMap.js";

export function getProductDetailPath(category: Category, product: Product): string {
	if (product.name.includes("VertiNode Ai")) {
		return `${solutionsPathMap[product.locale]}/${product.urlSlug}`;
	}
	return `${solutionsPathMap[product.locale]}/${categorySlugMap[category][product.locale]}/${product.urlSlug}`;
}
